/**
 * Formats a date in a nice way using translation phrases.
 * @param {string} dateValue Should be compatible with normal Date constructor
 * that can be used for input to Date constructor.
 * @param {Object} translation Translation object with phrases for current language.
 * @returns {string} The prettily formatted date string.
 */
export default function prettyTime(dateValue, translation) {
  const monthArray = [
    translation.shortJanuary,
    translation.shortFebruary,
    translation.shortMarch,
    translation.shortApril,
    translation.shortMay,
    translation.shortJune,
    translation.shortJuly,
    translation.shortAugust,
    translation.shortSeptember,
    translation.shortOctober,
    translation.shortNovember,
    translation.shortDecember,
  ];
  const dateObj = new Date(dateValue);
  let month = (dateObj.getMonth() + 1);
  const date = dateObj.getDate();
  const year = dateObj.getFullYear();
  let hour = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  month = monthArray[(month - 1)];

  if (hour < 10) {
    hour = `0${hour}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${date} ${month} ${year} - ${hour}:${minutes}`;
}
