<template>
  <div v-if="comment.comment !== undefined" ref="container">
    <dt class='bloob'>
      <b>{{ comment.firstName + ' ' + comment.lastName }}</b>{{ getPrettyTime(comment.date) }}
    </dt>
    <dd class='bloob'>
      {{ comment.comment }}
      <template v-if="comment.userId === user.id">
        <br>
        <a @click="deleteComment()" class='comment_delete lsc-rounded'
           :class="{ deleting: deleteStatus === 'deleting', confirm: deleteStatus === 'confirm' }">
          <template v-if="deleteStatus === ''">
            <icon name='fa fa-trash'/>{{ x('deleteComment') }}
          </template>
          <template v-if="deleteStatus === 'confirm'">
            <icon name='fa fa-exclamation-circle'/>{{ x('areYouSure') }}
          </template>
          <template v-if="deleteStatus === 'deleting'">
            <icon name='fa fa-cog fa-spin'/>{{ x('deletingDots') }}
          </template>
          <template v-if="deleteStatus === 'deleted'">
            <icon name='fa fa-check'/>{{ x('commentDeleted') }}
          </template>
        </a>
      </template>
    </dd>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import prettyTime from '../../classes/pretty-time';
  import translate from '../Mixins/Translate';
  import { httpDelete } from '../../classes/httpHelper';

  export default {
    name: 'item-comment',
    props: ['comment'],
    mixins: [translate],
    data() {
      return {
        deleteStatus: '',
        confirmTimer: null,
      };
    },
    computed: mapState(['config', 'user']),
    methods: {
      async deleteComment() {
        console.log('delete comment. DeleteStatus: ', this.deleteStatus);
        switch (this.deleteStatus) {
          case '':
            this.deleteStatus = 'confirm';
            this.confirmTimer = setTimeout(() => {
              this.deleteStatus = '';
            }, 2000);
            break;
          case 'confirm':
            this.deleteStatus = 'deleting';
            console.log('Deleting...');
            await this.performDelete();
            break;
          default:
            console.log('Ignoring extra clicks...');
            break;
        }
      },
      getPrettyTime(time) {
        return prettyTime(time, this.config.translation);
      },
      async performDelete() {
        await httpDelete(`visits/0/comments/${this.comment.id}`);
        this.deleteStatus = 'deleted';

        setTimeout(() => {
          const elComment = $(this.$refs.container);
          elComment.fadeOut();
          this.$emit('commentDeleted');
        }, 1500);
      },
    },
  };
</script>
